@import './vars.scss';

@font-face {
    font-family: 'Read';
    src: url('./../assets/fonts/Read-Regular_V3000.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Read Bold';
    src: url('./../assets/fonts/Read-Bold_V3000.ttf') format('truetype');
    font-style: normal;
}

@font-face {
    font-family: 'Read Medium';
    src: url('./../assets/fonts/Read-Medium_V3000.ttf') format('truetype');
    font-style: normal;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Read, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
        Droid Sans, Helvetica Neue, sans-serif;
    scroll-behavior: smooth;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

// general styling

h2 {
    font-size: 16px;
    line-height: 15px;
    font-family: 'Read bold';
    margin-bottom: 22px;
}

// antd overides

.ant-input {
    border: 1px solid $brand-color;
    height: 40px;
}

.ant-select-selector {
    border: 1px solid $brand-color !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-right: 0;
}

.ant-form-item-label > label {
    font-weight: bold;
}

.ant-form-item-explain.ant-form-item-explain-connected {
    // width: 100%;
    background: $error-color;
    opacity: 0.84;
    box-shadow: 0px 3px 6px rgba($error-color, 0.59);
    position: absolute;
    top: auto;
    bottom: 50px;
    transition: all 0.2s ease;

    min-width: 120px;
    padding: 4px 10px 4px 10px;

    &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 8px 0 8px;
        border-color: #b73e25 transparent transparent transparent;
    }

    .ant-form-item-explain-error {
        color: #fff;
        font-size: 11px;
    }
}

.ant-form-item-with-help {
    margin-bottom: 24px;
}
