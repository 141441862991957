.wrapper {
    max-width: 1111px;
    margin: 0 auto;
    padding: 50px 0;

    &.noIframeWrapper {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
